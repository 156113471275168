.neareststoresection{
    padding: 30px 10px 20px;
    .outdoorcard{
        display: block;
         overflow: hidden;
        .outdoorimg{
            img{
                width: 100%;
                height: auto;
                transition: all .3s ease;
                border-radius: 4px;
            }
        }
        &:hover{
            .outdoorimg{
                img{
                    transform: scale(1.04);
                }
            }

        }
    }
}

