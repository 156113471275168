.shoproomsection {
    padding: 30px 10px 0px;
    overflow: hidden;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    margin-top: 20px !important;

    .finestbrandheading {
        text-align: center;
        margin-bottom: 20px;

        .title {
            font-size: 23px;
            line-height: 27px;
            color: #333333;
            font-family: "Abhay-Libre Extra Bold";
            text-transform: capitalize;
        }

        .sub-title {
            font-size: 13px;
            line-height: 16px;
            font-family: "pangramregular";
            color: #646464;
            letter-spacing: 0.02em;
        }

    }

    .shoproomcontent {
        overflow: auto;
        white-space: nowrap;
        margin-right: -10px;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
    }

    .shoproomcard {
        display: inline-block;
        text-align: center;
        width: 60%;
        margin-right: 12px;
        margin-bottom: 10px;

        .shoproomimg {
            img {
                width: 100%;
                height: auto;
                border-radius: 3px;
            }

        }

        .shoproomtext {
            padding: 4px 0px 10px;

            .categoryname {
                font-size: 14px;
                line-height: 17px;
                color: #333333;
                font-weight: 500;
                font-family: "pangrammedium";
                text-transform: capitalize;
                margin: 0px;
                transition: 0.3s all ease-in-out;
                text-align: left;
            }
        }

        &:hover {
            .shoproomtext {
                .categoryname {
                    color: #E27A34;
                }
            }

        }
    }
}

.shoproombannersection {
    margin: 2px 0px 25px;
    padding: 0px 10px;

    .storeimg {
        a {
            display: block;
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}