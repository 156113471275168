.storemobsection {
    padding: 25px 10px 15px;
    background: #FFF3EC;

    .storemobheading {
        margin-bottom: 20px;
        text-align: center;

        .storemobtitle {
            font-size: 23px;
            line-height: 27px;
            color: #333333;
            font-weight: 800;
            font-family: "Abhay-Libre Extra Bold";
            text-transform: capitalize;
            letter-spacing: 0.2px;
            margin: 0px;
        }

        p {
            font-size: 12px;
            line-height: 16px;
            font-family: "pangramregular";
            color: #646464;
            font-weight: 400;
            letter-spacing: 0.03em;
            margin: 0px;
        }

    }

    .storemobcontent {
        overflow: auto;
        white-space: nowrap;
        margin-right: -10px;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
    }

    .storesoffer {
        border-radius: 50px;
        overflow: hidden;
        display: inline-flex;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        justify-content: center;
        margin: 5px 0px 0px;

        .stores {
            background-color: #4CAF4F;
            padding: 16px 20px;
            display: flex;
            align-items: center;

            h4 {
                color: #fff;
                font-size: 12px;
                line-height: 14.4px;
                font-weight: 400;
                font-family: "pangramregular";
                text-transform: uppercase;
                letter-spacing: 0.2px;
                margin: 0px;
            }
            .offerPrice {
                color: #fff;
                font-size: 12px;
                line-height: 14.4px;
                font-weight: 400;
                font-family: "pangramregular";
                text-transform: uppercase;
                letter-spacing: 0.2px;
                margin: 0px;
            }
        }

        .storesright {
            padding: 9px 10px;
            background-color: #fff;
            text-align: left;
            padding-right: 20px;

            p {
                color: #333;
                font-size: 12px;
                line-height: 14.4px;
                letter-spacing: 0.2px;
                font-weight: 400;
                font-family: "pangramregular";
                margin: 0px;

                span {
                    color: #E27A34;
                    font-family: "pangrammedium";
                    font-style: normal;
                }
            }
        }

    }

    .storecard {
        display: inline-grid;
        width: 55%;
        margin-right: 12px;
        overflow: hidden;
        margin-bottom: 15px;

        .storeimg {
            position: relative;
            overflow: hidden;

            figure {
                position: relative;
                overflow: hidden;
                border-radius: 3px;
                margin: 0px;
                display: block;
                background: #f5f5f5;

                &:before {
                    padding-top: 77%;
                    content: "";
                    display: block;
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 100%;
                height: 100%;
                border-radius: 3px;
                transform: scale(1.08);
                max-width: 100%;
            }
        }

        .storetext {
            transition: all 0.4s ease-in-out 0s;
            border-radius: 4px;
            background: linear-gradient(0deg, #000, rgba(255, 255, 255, 0));
            position: absolute;
            width: 100%;
            left: 0;
            top: 109px;
            bottom: 0px;
            right: 0;
            opacity: 1;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            padding-bottom: 11px;

            h4 {
                font-size: 14px;
                line-height: 16.8px;
                color: #fff;
                font-weight: 500;
                margin: 0px 0px 4px;
                text-transform: capitalize;
                font-family: "pangrammedium";
            }

            p {
                font-size: 12px;
                line-height: 14.4px;
                font-weight: 400;
                letter-spacing: 0.1px;
                color: #fff;
                font-family: "pangramregular";
                margin: 0px;

                span {
                    display: inline-block;
                    padding-left: 4px;
                }
            }
        }

        &:hover {
            .storeimg {
                img {
                    filter: grayscale(1);
                }
            }
        }
    }

    .allstoresbtn {
        text-align: center;
        margin: 10px auto 0px;

        a {
            font-size: 13px;
            line-height: 15.6px;
            border-radius: 5px;
            background-color: #E27A34;
            border-color: #E27A34;
            padding: 10px 26px;

        }
    }
}

@media(max-width:400px) {
    .storemobsection {
        .storecard {
            .storetext {
                top: 50px;
                background: linear-gradient(0deg, #000000cf, rgba(255, 255, 255, 0));
            }
        }
    }
}