.storesection {
    background: #FFF3EC;
    padding: 50px 0 30px;

    .storeheading {
        margin-bottom: 40px;
        text-align: center;

        h2 {
            font-size: 38px;
            line-height: 40px;
            color: #333333;
            font-family: "Abhay-Libre Bold";
            text-transform: capitalize;
            margin: 0px;
        }

        p {
            font-size: 15px;
            line-height: 20px;
            font-family: "pangramregular";
            color: #646464;
            font-weight: 400;
            letter-spacing: 0.03em;
            margin: 0px;
        }

    }

    .col-px {
        padding-right: 8px;
        padding-left: 8px;

    }

    .storecontent {
        display: grid;
        grid-template-columns: 1fr 2.5fr;
        align-items: center;

        .store-left {
            margin: 0 0 40px;
            text-align: center;

            .heading {
                font-size: 140px;
                line-height: 90px;
                color: #E27A34;
                text-transform: capitalize;
                font-family: "Abhay-Libre Bold";
                padding: 0 0 10px;

                span {
                    display: block;
                    font-size: 20px;
                    line-height: 26px;
                    text-transform: uppercase;
                    letter-spacing: 0.02em;
                    color: #515151;
                    font-family: "pangramregular";
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    position: relative;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 215px;

                    &:before {
                        content: "";
                        position: absolute;
                        border-top: 1px solid #cccccc;
                        bottom: 0;
                        width: 100px;
                        margin: 0 auto;
                        right: 0;
                        left: 0;
                    }
                }
            }

            .offer-box {
                span {
                    display: block;
                    font-family: "pangramregular";
                    color: #222;
                    text-transform: uppercase;
                    font-size: 17px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                }

                .strong {
                    display: block;
                    font-size: 65px;
                    line-height: 75px;
                    color: #4CAF4F;
                    font-family: "pangramregular";
                    text-transform: uppercase;
                    font-weight: normal;
                }

                .small {
                    font-family: "pangramregular";
                    font-size: 14px;
                    line-height: 20px;
                    color: #646464;
                    letter-spacing: 0.01em;
                }
            }
        }

        .store-right {
            margin-bottom: 40px;
        }
    }

    .storesoffer {
        border-radius: 50px;
        overflow: hidden;
        position: relative;
        width: 23.5%;
        margin: 0 5px 15px;

        .storeimg {
            position: relative;

            figure {
                position: relative;
                overflow: hidden;
                border-radius: 4px;
                background: #f5f5f5;
                display: block;

                &:before {
                    content: "";
                    display: block;
                    padding-top: 73%;
                }
            }

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                margin: auto;
                width: 100%;
                transition: 0.3s ease-in;
                display: inline-block;
                mix-blend-mode: darken;
                transform: scale(1);
                max-width: 100%;
                height: 100%;
            }
        }

    }

    .storecard {
        display: block;
        overflow: hidden;
        margin-bottom: 10px;

        .storeimg {
            position: relative;

            figure {
                position: relative;
                overflow: hidden;
                border-radius: 4px;
                background: #f5f5f5;
                display: block;

                &:before {
                    content: "";
                    display: block;
                    padding-top: 73%;
                }
            }

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                margin: auto;
                width: 100%;
                transition: 0.3s ease-in;
                display: inline-block;
                transform: scale(1);
                max-width: 100%;
                height: 100%;
            }
        }

        .storetext {
            transition: all 0.4s ease-in-out 0s;
            border-radius: 4px;
            background: linear-gradient(0deg, #000, rgba(255, 255, 255, 0));
            position: absolute;
            width: 100%;
            left: 0;
            top: 100px;
            bottom: 0px;
            right: 0;
            opacity: 1;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            padding-bottom: 12px;

            h4 {
                font-size: 18px;
                line-height: 20px;
                color: #fff;
                font-weight: 500;
                margin: 0px 0px;
                letter-spacing: 0.2px;
                text-transform: capitalize;
                transition: all 0.3s ease-in-out 0s;
                font-family: "pangramregular";
            }

            .storename {
                font-size: 18px;
                line-height: 20px;
                color: #fff;
                font-weight: 500;
                margin: 0px 0px;
                letter-spacing: 0.2px;
                text-transform: capitalize;
                transition: all 0.3s ease-in-out 0s;
                font-family: "pangramregular";
            }

            p {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #fff;
                font-family: "pangramregular";
                margin: 0px;
                font-weight: 400;

            }
        }

        &:hover {
            .storeimg {
                img {
                    filter: grayscale(1);
                }
            }
        }
    }

    .allstoresbtn {
        margin-top: 25px;

        .btn-primary {
            background-color: var(--bg-orange-light);
            border: 1px solid var(--bg-orange-light);
            color: #fff;
            font-size: 1.0625rem;
            line-height: 22px;
            font-family: "pangramregular";
            padding: 10px 20px;
            border-radius: 4px;
            letter-spacing: 0.02em;
            display: inline-block;

            &:hover {
                background-color: var(--bg-orange-light-hover);
                border: 1px solid var(--bg-orange-light-hover);
            }
        }
    }

    .store-content {
        display: grid;
        grid-template-columns: 1fr 2.5fr;
        align-items: center;
    }

    .store-card-right {
        width: 100%;
    }

    .store-card-left {
        text-align: center;
        margin-top: 30px;

        .btn-primary {
            background: var(--bg-orange-light);
            border-color: var(--bg-orange-light);
            color: #fff;
            font-size: 1.0625rem;
            line-height: 22px;
            font-family: "pangramregular";
            padding: 10px 20px;
            border-radius: 4px;
            letter-spacing: 0.02em;
            display: inline-block;
            margin: 0px;

            &:hover {
                background: var(--bg-orange-light-hover);
                border-color: var(--bg-orange-light-hover);
            }
        }
    }
}

@media (max-width:1366px) {
    .storesection {
        .storecard {
            .storetext {
                h4 {
                    font-size: 1.125rem;
                    line-height: 22px;
                }
                .storename {
                    font-size: 1.125rem;
                    line-height: 22px;
                }
            }

            h4 {
                display: block;
                font-family: "pangramregular";
                color: #333333;
                text-transform: uppercase;
                font-size: 14px;
                line-height: 42px;
                font-weight: 400;
                letter-spacing: 0.5px;
                margin: 0px;

                span {
                    font-weight: 500;
                    font-family: "pangrammedium";
                    padding: 0px 2px;
                    display: inline-block;
                }
            }

            .store-off {
                display: block;
                font-size: 54px;
                line-height: 42px;
                color: #4CAF4F;
                font-weight: 700;
                font-family: "pangramregular";
                text-transform: uppercase;
                margin: 0px 0px 10px;
            }

            p {
                font-family: "pangramregular";
                font-size: 12px;
                line-height: 14px;
                font-weight: 400;
                color: #646464;
                letter-spacing: 0.1px;
                margin: 0px auto 0px;
                max-width: 280px;
            }
        }

        .stores-list-row {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

    }
}

@media (max-width:1280px) {
    .store-section {
        padding: 30px 0px 60px;

        .storeheading {
            h2 {
                font-size: 32px;
                line-height: 36px;
            }
        }

        .storecard {
            .storetext {
                h4 {
                    font-size: 1rem;
                }
                .storename {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (max-width:991px) {
    .store-section {
        .store-card-left {
            .store-plus {
                font-size: 73px;
                line-height: 65px;

                span {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .store-off {
                font-size: 42px;
                line-height: 42px;
            }
        }

        .storecard {
            margin: 0 3px 10px;

            .storetext {
                top: 90px;

                h4 {
                    font-size: 14px;
                }
            }
        }

        .allstoresbtn {
            .btn-primary {
                padding: 8px 15px;
                font-size: 14px;
            }
        }
    }
}