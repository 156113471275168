.livingsection {
    padding: 50px 0 20px;

    .livingheading {
        margin-bottom: 30px;
        text-align: center;

        .headingnew {
            font-size: 38px;
            line-height: 40px;
            color: #333333;
            font-family: "Abhay-Libre Extra Bold";
            letter-spacing: 0.5px;
            text-transform: capitalize;
            margin: 0px 0px 0px;
        }

        p {
            font-size: 15px;
            line-height: 20px;
            font-family: "pangramregular";
            color: #515151;
            letter-spacing: 0.02em;
        }

    }

    .livingcard {
        display: block;
        overflow: hidden;
        text-align: center;
        margin-bottom: 20px;
        color: #333;
        font-family: 'pangramregular';

        img {
            width: 100%;
            height: 100%;
            transition: all .3s ease-in-out;
        }


        .livingtext {
            padding: 10px 0px 0px;

            p {
                color: #646464;
                font-size: 16px;
                line-height: 22px;
                max-width: 385px;
                margin: 0 auto;
                font-family: "pangramregular";
                letter-spacing: 0.02em;
                display: block;
                text-align: center;
            }

            h2 {
                font-family: "Abhay-Libre Bold";
                font-size: 34px;
                line-height: 36px;
                display: block;
                color: #333333;
                margin: 0px 0px 3px;
                letter-spacing: 0.2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .livingname {
                font-family: "Abhay-Libre Bold";
                font-size: 34px;
                line-height: 36px;
                display: block;
                color: #333333;
                margin: 0px 0px 3px;
                letter-spacing: 0.2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            h4 {
                color: #e27a34;
                font-family: "pangrammedium";
                text-transform: uppercase;
                font-size: 1.0625rem;
                line-height: 22px;
                letter-spacing: 0.03em;
                display: block;
                margin: 0px 0px 10px;
            }

            .livingoffer {
                color: #e27a34;
                font-family: "pangrammedium";
                text-transform: uppercase;
                font-size: 1.0625rem;
                line-height: 22px;
                letter-spacing: 0.03em;
                display: block;
                margin: 0px 0px 10px;
            }

            .livinglink {
                color: #515151;
                font-size: 14px;
                line-height: 20px;
                display: block;
                margin: 10px 0 0px;
                font-family: "pangramregular";
                letter-spacing: 0.02em;
            }
        }

        &:hover {
            .livinglink {
                color: #e27a34;
            }


            img {
                transform: scale(1.04);
            }
        }
    }
}

@media (max-width:1280px) {
    .livingsection {
        .livingheading {
            margin-bottom: 20px;

            h2 {
                font-size: 32px;
                line-height: 36px;
            }

            .headingnew {
                font-size: 32px;
                line-height: 36px;
            }
        }
    }
}

@media (max-width:767px) {
    .livingsection {
        padding: 15px 10px 0px;
        max-width: 100%;
        padding: 0px;
        border: 0px;

        .livingheading {
            margin-bottom: 20px;
            text-align: center;

            .headingnew {
                font-size: 23px;
                line-height: 27px;

            }

            h2 {
                font-size: 23px;
                line-height: 27px;

            }
        }
    }
}