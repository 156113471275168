.happyword-section {
    background-color: #f5f5f5;
    padding: 40px 0;


    .happyword-heading {
        margin-bottom: 40px;
        text-align: center;

        h2 {
            font-size: 1.875rem;
            line-height: 30px;
            color: #333333;
            font-weight: 600;
            font-family: "Abhay-Libre Bold";
            text-transform: capitalize;
            margin: 0px 0px 5px;
        }

        .headingNew {
            font-size: 1.875rem;
            line-height: 30px;
            color: #333333;
            font-weight: 600;
            font-family: "Abhay-Libre Bold";
            text-transform: capitalize;
            margin: 0px 0px 5px;
        }

        p {
            font-size: 0.9375rem;
            line-height: 20px;
            font-family: "pangramregular";
            color: #646464;
            font-weight: 400;
            margin: 0px;
        }

    }

    .happyword-content {
        margin-bottom: 40px;
    }

    .viewallbtn {
        text-align: center;

        .viewall-link {
            &:hover {
                svg {
                    path {
                        stroke: #e57200;
                        transition: 0.3s all ease-in-out;
                    }

                }
            }
        }
    }

    .happyword-card {
        display: block;
        padding: 10px 10px;
        background-color: #fff;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;

        .btn-view-product {
            border: 0;
            background-color: #fff;
            color: #333333;
            position: absolute;
            left: 0;
            right: 0;
            max-width: 180px;
            margin: 0 auto;
            bottom: 12px;
            font-size: 14px;
            line-height: 17px;
            padding: 8px 15px;
            font-weight: 300;
            text-align: center;
            font-family: "pangramregular";
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
            transition: 0.3s all ease;
            display: none;

        }

        .happyword-img {
            position: relative;
            overflow: hidden;

            figure {
                position: relative;
                overflow: hidden;
                margin-bottom: 6px;
                border-radius: 4px;
                background: #f5f5f5;

                &:before {
                    padding-top: 55.5%;
                    content: "";
                    display: block;
                }

                &:after {
                    content: "";
                    background: url(/images/home-new1/white-play.svg) no-repeat;
                    background-size: 60px;
                    width: 60px;
                    height: 60px;
                    display: inline-block;
                    position: absolute;
                    top: 60px;
                    right: auto;
                    left: auto;
                }

                img {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    margin: auto;
                    width: auto;
                    height: auto;
                    transform: scale(1);
                    transition: 0.3s ease-in-out;
                    display: block;
                }
            }


        }

        .happyword-text {
            p {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #646464;
                font-family: "pangramregular";
                padding: 5px 10px 10px;
                margin: 8px auto 10px;
                height: 106px;
                overflow: auto;
                display: block;

                &::-webkit-scrollbar {
                    width: 3px;
                    background-color: #F5F5F5;
                    padding-right: 10px
                }

                &::-webkit-scrollbar-track {
                    background-color: #F5F5F5
                }

                &::-webkit-scrollbar-thumb {
                    background: #707070
                }
            }

            h4 {
                font-family: "pangrammedium";
                font-size: 17px;
                line-height: 21px;
                color: #333333;
                font-weight: 500;
                letter-spacing: 0.02em;
                margin: 0px 0px 3px;
            }

            h5 {
                margin: 0;
                font-size: 15px;
                line-height: 20px;
                color: #333333;
                font-weight: normal;
                font-family: "pangrammedium";

                span {
                    font-size: 14px;
                    line-height: 20px;
                    color: #646464;
                    padding-top: 3px;
                    display: block;
                    color: #646464;
                }
            }

            .customer-name {
                margin: 0;
                font-size: 15px;
                line-height: 20px;
                color: #333333;
                font-weight: normal;
                font-family: "pangrammedium";

                span {
                    font-size: 14px;
                    line-height: 20px;
                    color: #646464;
                    padding-top: 3px;
                    display: block;
                    color: #646464;
                }
            }

            .rating {
                display: inline-block;
                border-top: 1px solid #e5e5e5;
                padding-top: 15px;
                margin-bottom: 8px;

                svg {
                    width: 18px;
                    height: 18px;
                    margin: 0px 2px;
                }

                ul {
                    display: inline-block;
                    padding-top: 15px;
                    margin-bottom: 8px;
                    border-top: 1px solid #e5e5e5;
                    text-align: center;

                    li {
                        display: inline-block;

                        img {
                            width: 18px;
                            height: 18px;
                        }

                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }

        &:hover {
            .btn-view-product {
                display: block;
                display: none;
            }

        }
    }
}

.happy-customer-video {
    height: 305px;
    border-radius: 0px;
    object-fit: cover;
    width: 100%;
}

@media(max-width:1280px) {
    .happyword-section {
        padding: 30px 0;

        .happyword-heading {
            margin-bottom: 30px;

            h2 {
                line-height: 29px;
                margin: 0px 0px 2px;
            }

            .headingNew {
                line-height: 29px;
                margin: 0px 0px 2px;
            }

            p {
                font-size: 0.875rem;
                line-height: 18px;
            }
        }

        .happyword-card {
            .happyword-text {
                height: 100%;

                p {
                    font-size: 0.8125rem;
                    padding-top: 0px;
                    height: 100px;
                }
            }
        }

        .happyword-content {
            margin-bottom: 30px;
        }
    }
}

@media (max-width:767px) {
    .happy-customer-video {
        width: 350px;
        height: 196px;

    }
}