.offerssection {
  margin: 10px 0px 0px;
  border-top: 1px solid #e5e5e5;

  .outdoorcard {
    display: block;
    overflow: hidden;

    .outdoorimg {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}