.footer-sale {
      transition: 0.3s;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0px;
    width: 100%;
    z-index: 9;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
        transform: translateY(0px);

    .footer-list {
        text-align: left;
        padding: 12px;
        width: 50%;
        text-transform: capitalize;
        position: relative;
        display: flex;

        svg {
            margin-right: 5px;
            width: 28px;
        }

        p {
            color: #333;
            font-size: 13px;
            letter-spacing: 0.02em;
            font-family: "robotomedium";
            line-height: normal;

            span {
                display: block;
                color: #E27A34;
            }
        }
    }

    .store-list {
        background: #FFF4F2;
    }
}
.footer-sale-sticky{
        transition: 0.3s;
          transform: translateY(55px);
            bottom: -6px;
            
}
@media(max-width:400px){
  .footer-sale {
    .footer-list {
            padding: 10px;
        svg {
            margin-right: 4px;
            width: 24px;
        }

        p {
            font-size: 12px;
        }
    }
}  
}