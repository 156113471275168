.livingmobileinner {
    padding: 20px 10px 10px;

    .livingmobileheading {
        margin-bottom: 25px;
        text-align: center;

        .title {
            font-size: 23px;
            line-height: 26px;
            color: #333333;
            font-family: "Abhay-Libre Extra Bold";
            text-transform: capitalize;
        }

        .sub-title {
            font-size: 13px;
            line-height: 16px;
            font-family: "pangramregular";
            color: #646464;
            letter-spacing: 0.02em;
        }

    }

    .livingmobilecard {
        display: block;
        overflow: hidden;
        text-align: center;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;
            transition: all .3s ease;
        }

        .livingtext {
            padding: 6px 0px 0px;

            .category-offer {
                color: #E27A34;
                font-size: 15px;
                line-height: 22px;
                font-family: "pangramregular";
                letter-spacing: 0.02em;
                display: block;
            }

            .category-name {
                font-family: "pangrammedium";
                font-size: 15px;
                line-height: 18px;
                display: block;
                color: #333333;
                font-weight: 500;
                margin: 0px 0px 2px;
            }

            .category-text {
                display: none;
                margin: 0px 0 0px;
                color: #E27A34;
                font-family: "pangramregular";
                font-size: 13px;
                line-height: 15.6px;
                font-weight: 400;
                letter-spacing: 0.2px;
                text-transform: uppercase;
            }

            .livinglink {
                color: #515151;
                font-size: 14px;
                line-height: 20px;
                display: block;
                margin: 10px 0 0px;
                font-weight: 400;
                font-family: "pangramregular";
                letter-spacing: 0.02em;
                transition: all .3s ease;
                display: none;
            }
        }

        &:hover {
            img {
                transform: scale(1.04);
            }


            .livingtext {
                .livinglink {
                    color: #E27A34;
                }

            }
        }
    }
}

.mettresmobilesection {
    margin: 10px 0px;
    padding: 0px 10px;
    img {
        width: 100%;
        height: auto;
    }
}

// @media(max-width:320px){
//     .livingmobilesection{
//         .livingmobileheading{
//             margin-bottom: 12px;
//             h2{
//                         font-size: 22px;
//         line-height: 25px;
//             }
//         }
//     }
// }
