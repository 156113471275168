.banner-section {
    padding: 15px 0px 30px;
    overflow: hidden;

    figure {
        position: relative;
        display: block;
        overflow: hidden;

        &:before {
            content: '';
            padding-top: 100%;
            display: block;
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            transition: 0.3s all ease-in-out;
            transform: scale(1);
            margin: auto;
            width: auto;
            height: auto;
        }
    }

    .banner-content {
        display: flex;

        .banner-left {
            width: 61%;
            margin-right: 10px;
            padding-left: 4px;

            .banner-slider {
                a {
                    display: block;

                    figure {
                        &:before {
                            padding-top: 61%;
                        }
                    }
                }


            }
        }

        .banner-right {
            width: 38%;

            a {
                display: block;

                figure {
                    &:before {
                        padding-top: 48%;
                    }
                }

                &:before {
                    content: "";
                    display: block;
                }

                img {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                    margin: auto;
                    width: auto;
                    height: auto;

                    transform: scale(1);
                    transition: 0.3s ease-in;

                }
            }


        }

    }
}

.discountsection {
    margin: 20px 0px;

    figure {
        position: relative;
        display: block;
        overflow: hidden;

        &:before {
            content: '';
            padding-top: 9%;
            display: block;
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            transition: 0.3s all ease-in-out;
            transform: scale(1);
            margin: auto;
            width: auto;
            height: auto;
        }
    }
}

@media (max-width:1366px) {
    .banner-section {
        padding: 10px 10px 0px;

        .banner-content {
            .banner-left {
                padding-left: 1px;
            }
        }
    }

    .discountsection {
        padding: 0 10px;
    }
}