.recommendedsection {
    padding: 20px 0px 15px;
    background-color: #F5F5F5;

    .recommendedheading {
        margin: 0 0 10px;
        text-align: center;

        h2 {
            font-size: 23px;
            line-height: 27px;
            color: #333333;
            font-weight: 800;
            font-family: "Abhay-Libre Extra Bold";
            text-transform: capitalize;
            letter-spacing: 0.2px;
            margin: 0px;
        }

        p {
            font-size: 13px;
            line-height: 20px;
            font-family: "pangramregular";
            color: #515151;
            letter-spacing: 0.5px;
            font-weight: 400;
            margin: 0px;
        }
    }

    .recomcard {
        display: block;
        overflow: hidden;

        .recomimg {
            position: relative;
            overflow: hidden;

            figure {
                position: relative;
                overflow: hidden;
                border-radius: 3px;
                background: #f5f5f5;

                &:before {
                    content: "";
                    display: block;
                    padding-top: 82%;

                }
            }

            .imgrec {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                margin: auto;
                width: 100%;
                height: 100%;
                transition: 0.3s ease-in-out;
            }
        }

        .recomimgbig {
            figure {
                border-radius: 0px;

            }
        }

        .recombtnover {
            position: absolute;
            text-transform: capitalize;
            border-radius: 4px;
            display: block;
            left: 3px;
            top: 3px;
            padding: 0px 0px 0;
            display: none;

            img {
                width: auto !important;
                height: auto !important;
                transform: none !important;
                max-width: 85px;
            }

            label {
                font-size: 11px;
                line-height: 14px;
                font-family: "pangrammedium";
                right: 2px;
                color: #fff;
                top: 4px;
                position: absolute;

            }
        }

        .recomtext {
            padding: 2px 0px 10px;

            p {
                font-size: 13px;
                line-height: 20px;
                color: #333333;
                font-weight: 400;
                letter-spacing: 0.5px;
                margin: 0px 0px;
                text-transform: capitalize;
                font-family: "pangramregular";
            }
        }

        &.recomcardbig {
            margin-bottom: 15px;

            .recomtext {
                text-align: center;
                padding-top: 3px;
                display: none;

                p {
                    font-size: 0.875rem;
                    line-height: 18px;
                    letter-spacing: 0.0em;
                    margin-bottom: 5px;
                }
            }

        }

        &:hover {
            .recomimg {
                img {
                    transform: scale(1.04);
                }
            }

            .recomimgbig {
                img {
                    transform: none;
                }
            }

            .recomtext {
                p {
                    color: #E27A34;
                }
            }

        }
    }
}
