.emi-section {
    padding: 15px 10px 5px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;

    .emi-card {
        text-align: center;

        .emi-card-img {
            background: #fff4f2;
            border-radius: 50%;
            height: 52px;
            padding: 10px;
            width: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px auto 1px;

            img {
                width: 40px;
                height: auto;
                position: relative !important;
            }
        }

        .emi-card-text {
            font-size: 12px;
            line-height: 16px;
            color: #333333;
            margin: 8px 0px 10px;
            letter-spacing: 0.02em;
            font-family: "pangramregular";
        }
    }


}