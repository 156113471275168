.news-section {
  padding: 70px 0px;
  text-align: center;
  border-top: 1px solid #e5e5e5;

  .news-button {
    text-align: left;
  }

  .news-right-card {
    display: flex;
    justify-content: flex-end;
  }

  .headingNew {
    font-size: 38px;
    line-height: 40px;
    color: #333333;
    font-family: "Abhay-Libre Bold";
    text-transform: capitalize;
    padding: 0 0 30px;
    text-align: left;
    position: relative;
    margin-bottom: 20px;

    .subheading {
      font-size: 15px;
      line-height: 20px;
      font-family: "pangramregular";
      color: #515151;
      text-align: left;
      letter-spacing: 0.02em;
      display: block;
    }

    &:after {
      content: '';
      border: 1px solid #f9763a;
      width: 105px;
      display: block;
      position: absolute;
      bottom: 10px;
      text-align: left;
    }
  }

  .media-images {
    img {
      height: auto;
    }
  }

  .view-link {
    padding: 10px;
    font-size: 15px;
    margin-top: 20px;
    color: #e76f24;
    border-radius: 3px;
    display: inline-block;
    border: 1px solid #e76f24;
    transition: 0.3s all ease-in-out;
    font-family: "pangramregular";

    &:hover {
      color: #fff;
      background: #e27a34;
      transition: 0.3s all ease-in-out;
    }
  }

  .flex {
    display: flex;
    margin: 0;
  }

  .media-block {
    background: #fff6f0;
    padding: 10px;
    border-radius: 5px;
    letter-spacing: 0.02em;
    max-width: 320px;
    margin-right: 10px;
    text-align: left;
    white-space: normal;

    figure {
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      background: #f5f5f5;
      margin-bottom: 10px;

      img {
        height: auto;
      }
    }

    &:hover {
      .media-head {
        color: #e27a34;
        text-decoration: underline;
      }
    }

    .media-head {
      font-size: 18px;
      line-height: 20px;
      color: #333333;
      font-family: 'pangrammedium';
      margin: 6px 0 3px;
      transition: 0.3s all ease-in-out;
    }

    .media-subhead {
      color: #515151;
      font-size: 14px;
      line-height: 20px;
      font-family: 'pangramregular';
    }
  }

  .customer-reviews {
    .testimonial-heading {
      font-family: 'Abhay-Libre Bold';
      font-size: 1.875rem;
    }

    .in {
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      figure {
        height: auto;
      }

      figure:before {
        padding-top: 55.5% !important;
      }

      figure img {
        max-height: unset !important;
      }

      figure:after {
        content: '';
        background: url('/images/home-new1/white-play.svg') no-repeat;
        background-size: 65px;
        width: 60px;
        height: 60px;
        display: inline-block;
        position: absolute;
        top: 70px;
        right: 0;
        left: 165px;
      }

      .text-common {
        padding: 5px 10px 0px;
        height: 106px;
      }
    }

    .customer-slider .slick-next {
      -webkit-transform: none;
      transform: none;
      right: 5px;
    }

    .customer-slider .slick-next:before {
      background: url('/images/home-new1/CaretRight.svg') no-repeat !important;
    }

    .customer-slider .slick-prev {
      left: 5px;
      z-index: 1;
    }

    .info {
      color: #333333;
      font-family: 'pangrammedium';
      letter-spacing: 0.02em;
    }

    .info span {
      color: #646464;
      font-family: 'pangramregular';
      line-height: 20px;
      font-size: 14px;
    }
  }

  .Video-Open-Wrpr {
    background: #fff;
    padding: 15px 20px 20px;
    border-radius: 5px;

    .close-popup {
      text-align: right;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }

  .cat_btn {
    opacity: 0;
  }
}

.viewmorebtndiv {
  text-align: center;
  margin-top: 20px;

  .view-all-furni {
    padding: 8px 20px;
    font-size: 14px;
    line-height: 18px;
    background: transparent;
    color: #515151;
    border: 1px solid #8b8b8b;
    border-radius: 4px;
    font-family: "pangrammedium";
    text-transform: capitalize;
    display: none;
  }
}

@media (max-width: 1280px) {
  .news-section {
    .headingNew {
      font-size: 32px;
      line-height: 36px;
      padding: 0 0 20px;
    }
  }
}

@media (max-width: 767px) {
  .viewmorebtndiv {
    .view-all-furni {
      display: inline-block;
      margin: 0px;
    }

  }

  .news-section {
    padding: 30px 10px;
    text-align: center;
    border-top: 1px solid #e5e5e5;

    .news-right-card {
      overflow: auto;
      white-space: nowrap;
      display: revert;
      margin-right: -10px;

      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        display: none;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
    }

    .headingNew {
      font-size: 23px;
      line-height: 26px;
      font-family: "Abhay-Libre Extra Bold";
      letter-spacing: 0.01em;
      padding: 0px;
      text-align: center;

      .subheading {
        font-size: 12px;
        line-height: 16px;
        color: #646464;
        letter-spacing: 0.01em;
        display: block;
        text-align: center;
      }

      &:after {
        display: none;
      }
    }

    .media-images {
      margin-bottom: 20px;
      display: block;
    }

    .view-link {
      display: none;
    }

    .media-block {
      max-width: 280px;
      min-width: 280px;
      height: 100%;
      min-height: 290px;
      display: inline-grid;


      figure {
        margin-bottom: 6px;

      }

      .media-head {
        font-size: 15px;
        line-height: 18px;
      }

      .media-subhead {
        color: #646464;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

}

@media (max-width: 320px) {
  .news-section {
    .headingNew {
      font-size: 22px;
      line-height: 25px;
      margin-bottom: 12px;
    }
  }
}

// @media (max-width: 575px) {
//   .newsItems {
//     text-align: center;
//     padding: 30px 10px;
//     border-top: 1px solid #e5e5e5;
//     .headingNew {
//       font-size: 23px;
//       line-height: 26px;
//       text-transform: capitalize;
//       color: #333333;
//       font-family: 'Abhay-Libre Extra Bold';
//       letter-spacing: 0.01em;
//     }
//     .view-link {
//       display: none;
//     }
//   }
//   .flex {
//     display: block !important;
//     overflow: auto;
//     white-space: nowrap;
//     margin-right: -10px;
//     .media-block {
//       background: #fff6f0;
//       padding: 10px;
//       border-radius: 5px;
//       letter-spacing: 0.02em;
//       max-width: 280px;
//       margin-right: 10px;
//       display: inline-block;
//       vertical-align: top;
//       min-height: 290px;
//     }
//     .media-head {
//       font-size: 15px;
//       line-height: 18px;
//       color: #333333;
//       font-family: 'pangrammedium';
//       margin: 6px 0 3px;
//       white-space: normal;
//       text-align: left;
//     }
//     .media-subhead {
//       color: #646464;
//       font-size: 13px;
//       line-height: 18px;
//       font-family: 'pangramregular';
//       white-space: normal;
//       text-align: left;
//     }
//   }
//   .cat_btn {
//     opacity: 1 !important;
//     margin: 20px 0px 0;
//     padding: 8px 20px;
//     font-size: 14px;
//     line-height: 18px;
//     background: #fff;
//     color: #515151;
//     border: 1px solid #8b8b8b;
//     border-radius: 4px;
//     font-family: 'pangrammedium';
//     display: inline-block;
//   }
// }