.custstoriemobsection {
    background-color: #F5F5F5;
    padding: 25px 10px 25px;

    .custstoriemobheading {
        margin-bottom: 20px;
        text-align: center;

        h2 {
          text-align: center;
            font-size: 1.0625rem;
            line-height: 21px;
            text-transform: uppercase;
            color: #333333;
            font-family: "pangrammedium";
            letter-spacing: 0.05em;
            margin: 0;
        }

        p {
            font-size: 0.8125rem;
             line-height: 20px;
            font-weight: 400;
            font-family: "pangramregular";
            color: #646464;
            letter-spacing: 0.1px;
            margin: 0px;
        }
    }

    .custstoriemobcontent {
        overflow: auto;
        white-space: nowrap;
        margin-right: -10px;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
    }


    .happywordcard {
        display: inline-block;
        padding: 10px 14px;
        background-color: #fff;
        margin-bottom: 10px;
        margin-right: 12px;
        width: 100%;
        max-width: 289px;
        box-shadow: 0 3px 6px #dbdada;
        border-radius: 12px;
        position: relative;

        .happywordimg {
            position: relative;
            &:after {
                content: "";
                background: url(/images/home-new1/white-play.svg) no-repeat;
                background-size: 60px;
                width: 60px;
                height: 60px;
                display: inline-block;
                position: absolute;
                top: 40px;
                right: 38%;
                // left: auto;
            }

            img {
                width: 100%;
                height: 143px;
                object-fit: cover;
                transition: all .3s ease;
                display: block;
                border-radius: 5px;
            }
        }

        .happywordtext {
            padding: 18px 0px 0px;

            p {
                font-size: 13px;
                line-height: 18px;
                font-weight: 400;
                color: #515151;
                font-family: "pangramregular";
                margin: 0px auto 14px;
                overflow: auto;
                height: 75px;
                white-space: normal;
                letter-spacing: 0.01em;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background: #c8c8c8;
                }
            }

            .customer-name {
                margin: 0;
                font-size: 15px;
                line-height: 20px;
                color: #333333;
                font-weight: normal;
                font-family: "pangrammedium";

                span {
                    font-size: 14px;
                    line-height: 20px;
                    color: #646464;
                    padding-top: 3px;
                    display: block;
                    color: #646464;
                }
            }
            h4 {
                font-family: "pangrammedium";
                font-size: 17px;
                line-height: 21px;
                color: #333333;
                font-weight: 500;
                letter-spacing: 0.02em;
                margin: 0px 0px 3px;
            }

            h5 {
                margin: 0 0px 4px;
                font-size: 13px;
                line-height: 15.6px;
                color: #333333;
                font-weight: 400;
                font-family: "pangramregular";

                span {
                    display: inline-block;
                }
            }

            .rating {
                margin-bottom: 5px;

                svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 2px;
                }

                ul {
                    display: inline-block;
                    margin-bottom: 5px;

                    li {
                        display: inline-block;

                        img {
                            width: 18px;
                            height: 18px;
                        }

                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }

            .viewproductlink {
                font-size: 0.75rem;
                line-height: 15px;
                color: #E27A34;
                font-weight: normal;
                display: block;
                font-family: "pangramregular";
            }
        }

        &:hover {
            .btnviewproduct {
                display: block;
            }

        }
    }

    .viewmorebtndiv {
        text-align: center;
        margin-top: 15px;

        .view-all-furni {
            padding: 8px 30px;
            font-size: 14px;
            line-height: 18px;
            background: transparent;
            color: #515151;
            border: 1px solid #8b8b8b;
            background-color: transparent;
            border-radius: 4px;
            font-family: "pangrammedium";
            text-transform: capitalize;
            
        }
    }
}
.happy-customer-video{
            height: 172px;
}