.mettresssection {
    .mettressinner {
        display: flex;
    }

    .mettressimg {
        display: block;
        width: 49%;
        margin: 50px 0px;

        img {
            width: 100%;
            height: auto;
            display: block;
            border-radius: 3px;
        }
    }

    .mettressimg-off {
        margin-right: 15px;

        img {
            border-radius: 0px;
        }

    }
}

@media (max-width:767px) {
    .mettresssection {
        .mettressinner {
            display: block;
        }

        .mettressimg {
            width: 100%;
            margin: 20px auto;
            padding: 0 10px;
        }
    }

}