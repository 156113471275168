.finestbrandmobsection {
    padding: 25px 10px 0px;

    .finestbrandheading {
        text-align: center;
        margin-bottom: 10px;

        .headingNew {
            font-size: 22px;
            line-height: 26px;
            color: #333333;
            font-family: "Abhay-Libre Extra Bold";
            text-transform: capitalize;
            letter-spacing: 0.5px;
            margin: 0px;
            h1 {
                font-size: 22px;
                display: inline;
                margin: 0;
            }
        }

        p {
            font-size: 13px;
            line-height: 18px;
            font-family: "pangramregular";
            color: #646464;
            font-weight: 400;
            letter-spacing: 0.5px;
            margin: 0px;
            position: relative;
            overflow: hidden;
            width: 100%;
            margin: auto;
            white-space: nowrap;

        }

        div {
            font-size: 13px;
            line-height: 18px;
            font-family: "pangramregular";
            color: #646464;
            font-weight: 400;
            letter-spacing: 0.5px;
            margin: 0px;
            position: relative;
            overflow: hidden;
            width: 100%;
            margin: auto;
            // white-space: nowrap;
        }

        .readmore {
            color: #E27A34;
            font-size: 12px;
            position: absolute;
            right: 0px;
            z-index: 1;
            top: 0px;
            background-color: #fff;
            padding: 1px 0px;
            cursor: pointer;
        }

        a {
            color: #E27A34;
        }
    }

    .finestbrandcard {
        display: block;

        img {
            width: 100%;
            height: auto;
            border-radius: 3px;
            display: block;
        }
    }
}